export const matchmakerEvents = {
  newCTAEvent: {
    OTP_EVENT: 'continue_with_OTP',
    WA_EVENT: 'continue_with_WA',
  },
  newLoginEvent: {
    LOGIN_CONTINUE: 'Login_continue_clicked',
    LOGIN_BACK: 'Login_form_back',
    LOGIN_SUCCESS: 'Login_success',
    LOGIN_FAILED: 'Login_failed',
    VERIFY_OTP: 'Verify_OTP_clicked ',
    OTP_RESEND: 'OTP_resend',
    OTP_BACK: 'OTP_screen_back',
  },
  FORM_Events: {
    SYR_autofill_ON: 'SYR_autofill_click_ON',
    SYR_autofill_OFF: 'SYR_autofill_click_OFF',
    MM_SELECTED_DISTRICT: 'mm_selected_district',
    SYR_CLOSE_REQ_SUBMIT: 'SYR_close_req_submit',
    SYR_CLOSE_REQUEST: 'SYR_close_request',
    SYR_CARD_BACK: 'SYR_card_back'
  },
}

export const pageType = {
  SYR: 'SYR',
  SYR_REVIEW: 'SYR_review',
  OTP_SCREEN: 'OTP_screen',
  LOGIN_SCREEN: 'Login_screen',
  LOGIN_FAILED: 'Login_failed',
  LOGIN_SUCCESS: 'Login_success',
  SHARE_REQUIREMENT: 'shareRequirement',
  MM_SUCCESS_PAGE: 'Success_page',
  SYR_Header: 'SYR_Header',
  SYR_FORM_SUBMIT:'SYR_Form_submit',
  AGENT_DETAILS: 'Agent Details',
  PROFILE_PAGE: 'Profile_Page',
  MYPROFILE_CLICK: 'MyProfile_click'
}

export const eventData = {
  LOGIN_TYPE: 'MM',
  INITIATION_SCREEN: 'MM',
  PLACEMENT: ['on_MM_Form', 'On_Login_Form', 'Preview_Popup_PDP'],
}

export const viewMode = {
  mapView: 'map_view',
  listView: 'list_view',
  bothView: 'map_and_list_views',
}
export const propertyCategory = {
  COMMERCIAL: 'commercial',
  RESIDENTIAL:'residential',
  BANNER_POST_PROPERTY: 'bannerForPostProperty',
}

export const orderType = {
  ORDER_TYPE_CTA: 'Order_Type_CTA',
  HEADER_CTA: 'Header_cta'
}

export const getLanguage = {
  EN: 'en',
  AR: 'ar'
}

export const dispatchConst = {
  SET_BANK_DETAILS: 'SET_BANK_DETAILS',
  SET_REQUIREMENT_ID : 'SET_REQUIREMENT_ID',
  SET_PRE_APPROVED: 'SET_PRE_APPROVED',
  SET_EMPLOY_MODE: 'SET_EMPLOY_MODE',
  SET_MONTHLY_SALARY: 'SET_MONTHLY_SALARY',
  SET_AGE:'SET_AGE'
}

export const bedroomValue = {
  EN_BEDROOM_ID : 'Studio',
  AR_BEDROOM_ID : 'ستوديو'
}

export const BASE_URL = 'https://www.wasalt.com'

export const SYRConstant = {
  MAX_PRICE:'maxPrice',
  MIN_PRICE:'minPrice',
  MIN: 'min',
  MAX:'max',
  REQ_BUY:'req-buy',
  REQ_RENT:'req-rent',
  RENT:'rent',
  BUY:'buy',
  SALE: 'sale',
  REQ_CASH: 'req-cash',
  REQ_MORTGAGE: 'req-mortgage',
  OUTSIDE_OF_WASALT: 'outsideOfWasalt',
  ROUTER_PARAMS: 'routerParams',
  BANK_LOGO: 'logo',
  BANK_NAME: 'name',
  BANK_DATA: 'data',
  BANK_SLUG: 'slug',
  PRE_APPROVED: 'preApproved',
  EMPLOYMENT_MODE: 'employmentMode',
  MONTHLY_SALARY: 'monthlySalary',
  BUY_DEFAULT_BEDROOM: ['4', '5'],
  RENT_DEFAULT_BEDROOM: ['2', '3'],
  AGE:'age'
}
export const errorLable = {
  CLOSE_REUIREMENT : 'Fail to fetch close requirement'
}