import { isIOS, isAndroid } from 'react-device-detect'
import { objectRemoveEmptyData, pushDataLayerEventHandler } from '@/utils/utils'
import { propTypeAndCategoryEvents } from './propertyDetailsService'
import { listingValues, priceRange, rentPriceRange } from '@/utils/constant'
import { SYRConstant } from '@/components/ShareYourRequirement/MatchmakerConstant'
import uniqueIdGenerator from '@/utils/generateUniqueId'
import dayjs from 'dayjs'

export const getPropertyTypeSubCategory = async (lang) => {
  const requestBody = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      locale: lang,
    },
  }

  const [commercialRes, residentialRes] = await Promise.all([
    fetch(`${process.env.NEXT_PUBLIC_API_URL_V1}/search-box/sub-property-types/commercial`, requestBody),
    fetch(`${process.env.NEXT_PUBLIC_API_URL_V1}/search-box/sub-property-types/residential`, requestBody),
  ])
  const [commercial, residential] = await Promise.all([commercialRes.json(), residentialRes.json()])

  return { commercial, residential }
}

export const getMasterData = async (lang) => {
  const requestBody = {
    method: 'POST',
    body: JSON.stringify({
      types: [
        'property_main_type',
        'requirement_listing_type',
        'requirement_property_type_residential',
        'requirement_property_type_commercial',
        'requirement_purchase_type',
        'requirement_outside_wasalt_reasons',
        'requirement_fullfilment_failure_reasons',
        'requirement_agents_feedback',
        'requirement_iam',
        'requirement_timeframe',
        'requirement_commercial_sub_type',
      ],
    }),
    headers: {
      locale: lang,
      'Content-Type': 'application/json;charset=UTF-8',
      'x-request-id': uniqueIdGenerator.getId()
    },
  }
  const mastersRes = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/type-masters`, requestBody)
  return mastersRes.json()
}

export const getDistrict = async (cityId, lang, zone_id = null) => {
  const districtRes = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V1}/zones`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      locale: lang,
      'x-request-id': uniqueIdGenerator.getId()
    },
    body: JSON.stringify({
      city_id: cityId,
      zone_id,
    }),
  })
  return districtRes.json()
}

export const postAuctionLiteData = async (data, lang) => {
  try {
    data.source = 'WEB'
    const response = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL}leads-connect/auction`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        locale: lang,
        Cookie: `i18next=${lang}`,
        authorization: window.localStorage.getItem('authToken') ?? '',
      },
      body: JSON.stringify(data),
    })
    return await response.json()
  } catch (e) {
    return e
  }
}

export const postProjectLiteData = async (data, lang) => {
  try {
    data.source = 'WEB'
    const response = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL}leads-connect/compound`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        locale: lang,
        Cookie: `i18next=${lang}`,
        authorization: window.localStorage.getItem('authToken') ?? '',
      },
      body: JSON.stringify(data),
    })
    return await response.json()
  } catch (e) {
    return e
  }
}

export const postMuhaktatLiteData = async (data, lang) => {
  try {
    data.source = 'WEB'
    const response = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL}leads-connect/muhakatat`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        locale: lang,
        Cookie: `i18next=${lang}`,
        authorization: window.localStorage.getItem('authToken') ?? '',
      },
      body: JSON.stringify(data),
    })
    return await response.json()
  } catch (e) {
    return e
  }
}

export const uploadFiles = async (slug, data, lang) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL_V1}module-file-upload/${slug}`, {
      method: 'POST',
      headers: {
        locale: lang,
        Cookie: `i18next=${lang}`,
        authorization: window.localStorage.getItem('authToken') ?? '',
      },
      body: data,
    })
    return await response.json()
  } catch (e) {
    return e
  }
}

export const commonEventData = (data, cities, districts, productName, files) => {
  const city = cities?.data?.allCities?.filter((cityData) => cityData.id === data?.cityId)[0]?.name
  const district = districts?.data?.filter((districtData) => districtData.id === data?.districtId)[0]?.name
  const eventObject = {
    Product_name: productName,
    Property_type: data?.mainTypeId,
    Property: data?.propertyTypeId,
    expected_price: data?.price,
    Photos_uploaded: files ? true : false,
    Purpose: null,
    Units: null,
    city,
    District: district,
    youtube: data?.youtube ? true : false,
  }
  if (productName === 'project') {
    eventObject.price_max = data?.maxPrice === 10010000 ? '10,000,000+' : data?.maxPrice
    eventObject.price_min = data?.minPrice
    eventObject.Purpose = data?.listingTypeId
    eventObject.Units = data?.totalUnit
  }
  if (productName === 'masterplan') {
    eventObject.price_max = data?.maxPrice === 10010000 ? '10,000,000+' : data?.maxPrice
    eventObject.price_min = data?.minPrice
    eventObject.Purpose = data?.listingTypeId
    eventObject.Units = data?.totalUnit
  }

  return eventObject
}

export const submitAnalyticEvent = (
  data,
  cities,
  districts,
  productName,
  screenName,
  eventName,
  window,
  lang,
  files,
) => {
  const eventData = commonEventData(data, cities, districts, productName, files)
  const isAndroidCheck= isAndroid ? 'android' : 'Web'
  pushDataLayerEventHandler(window, lang, eventName, 'Auction Form', {
    Screen_name: screenName,
    platform: isIOS ? 'iOS' : isAndroidCheck,
    Ownership: localStorage.getItem('role') ? JSON.parse(localStorage.getItem('role')) : null,
    ...eventData,
  })
}
// AB_Test_Show_int_Variant used for matchmaker form events
const syrDataModificationForAnalytics = (values) => {
  const paymentModeCheck= (values?.paymentType == '0' ? 'Cash' : 'Mortgage')
  const eventObject = {
    Api_response: values?.Api_response || '',
    Page_sub_type: values?.Page_sub_type,
    auto_filled_clicked: values?.auto_filled_clicked,
    auto_filled_num: values?.auto_filled_num,
    bedrooms: Array.isArray(values?.bedroomId) ? values?.bedroomId?.join() : values?.bedroomId?.toString() || '',
    city: values?.cityId,
    district: values?.district,
    property_subtype: Array.isArray(values?.propertyType) ? values?.propertyType?.join() : values?.propertyType,
    property_type: values?.propertyCategory,
    buy_mode: values?.paymentType ? paymentModeCheck : '',
    mortgage_preapprove: values?.preApproved,
    specific_req: values?.specificRequirement,
    terms: values?.terms,
    i_am: values?.userType,
    request_id: values?.requirementId || '',
    budget: {
      minPrice: values?.minPrice || '0',
      maxPrice: values?.maxPrice || (values?.listingTypeId == 419 ? priceRange : rentPriceRange)?.max,
    },
    fields_overwrite: values?.fields_overwrite,
    listing_type: values?.listingTypeId,
    overwrite_num: values?.overwrite_num,
    source: values?.source,
    timeframe: values?.timeframe,
    user_id: values?.user_id,
    AB_Test_Show_int_Variant: values?.showint_variant,
    Login_WA_MM : values?.abVarient_WA,
    apply_mortgage_offer: values?.preApproved ? true : false,
    service: values?.listingTypeId === listingValues[0] ? SYRConstant.BUY : SYRConstant.RENT,
    Rize_status: values?.listingTypeId === listingValues[1] && values?.preApproved ? true : false,
    first_req : values?.first_req,
  }
  objectRemoveEmptyData(eventObject)
  return eventObject
}

export const syrAnalytics = ({ values, window, lang, eventName, screenName = 'SYR' }) => {
  const modifiedPorperties = syrDataModificationForAnalytics(values)
  pushDataLayerEventHandler(window, lang, eventName, 'SYR', {
    Screen_name: screenName,
    ...modifiedPorperties,
  })
}
// AB_Test_Show_int_Variant used for matchmaker form events
const syrLiteObjectModification = (values) => {
  const listingType = (values?.listingTypeId == '3' ? 'Buy' : 'Rent')
  const paymentType = (values?.paymentType == '0' ? 'Cash' : 'Mortgage')
  const paymentTypeCheck= (values?.paymentType ? true : false)
  const iamCheck = (values?.iam == '0' ? 'Property Seeker' : 'Broker')
  const eventObject = {
    bedrooms: values?.bedrooms,
    city: values?.city,
    district: values?.district?.join(','),
    property_subtype: values?.listingTypeId ? listingType : '',
    property_type: values?.lookingFor,
    buy_mode: values?.paymentType ? paymentType : '',
    mortgage_preapprove: values?.paymentType ? paymentTypeCheck : '',
    specific_req: values?.note,
    terms: values?.terms,
    i_am: values?.iam ? iamCheck : '',
    request_id: values?.requirementId,
    position: values?.position,
    is_LRP_NRP: values?.is_LRP_NRP,
    page_type: values?.page_type,
    source: values?.source,
    type: values?.type,
    login_status: window.localStorage.getItem('authToken') ? true : false,
    user_id: values?.user_id,
    EVENT_ID: values?.EVENT_ID,
    Filter_Description: values?.Filter_Description,
    callType: values?.callType,
    Advertiser_ID: values?.Advertiser_ID,
    query_parameter: values?.query_parameter || '',
    AB_Test_Show_int_Variant: values?.showint_variant,
    eligibility: values?.eligibility,
    matched_brokers_count: values?.brokerCount,
    variant: values?.variant,
    draft_id: values?.draft_id,
    Login_WA_MM : values?.abVarient_WA,
    ...propTypeAndCategoryEvents(values),
  }
  objectRemoveEmptyData(eventObject)
  return eventObject
}

export const syrAnalyticsLite = ({ window, lang, eventName, screenName = 'SYR', data = {} }) => {
  const isAndroidCheck= isAndroid ? 'android' : 'Web'
  const modifiedPorperties = syrLiteObjectModification(data)
  pushDataLayerEventHandler(window, lang, eventName, 'SYR', {
    Screen_name: screenName,
    platform: isIOS ? 'iOS' : isAndroidCheck,
    Ownership: 'role' in localStorage ? JSON.parse(localStorage.getItem('role')) : null,
    ...modifiedPorperties,
  })
}
export const auctionAnalyticsLite = ({ window, lang, eventName, screenName = 'Auction', data = {} }) => {
  const modifiedPorperties = syrLiteObjectModification(data)
  const isAndroidCheck= isAndroid ? 'android' : 'Web'
  pushDataLayerEventHandler(window, lang, eventName, 'Auction', {
    Screen_name: screenName,
    platform: isIOS ? 'iOS' : isAndroidCheck,
    Ownership: localStorage.getItem('role') ? JSON.parse(localStorage.getItem('role')) : null,
    ...modifiedPorperties,
  })
}

export const storeSuccessData = (data) => {
  sessionStorage.setItem('req_submitted_data', JSON.stringify(data))
}

/* fetch(`${process.env.NEXT_PUBLIC_API_URL}/misc/v2/maps/kml`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      locale: locale,
    }
  }) */
export const checkContactExistence = async (data, lang) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL}check-contact-existence`, {
      method: 'POST',
      headers: {
        locale: lang,
        'Content-Type': 'application/json',
        'x-request-id': uniqueIdGenerator.getId()
      },
      body: JSON.stringify(data),
    })
    return await response.json()
  } catch (e) {
    return e
  }
}

export const resendVerificationCode = async (data, lang) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL}resend-verfication-code`, {
      method: 'POST',
      headers: {
        locale: lang,
        'Content-Type': 'application/json',
        //Cookie: `i18next=${lang}`,
        //authorization: window.localStorage.getItem('authToken') ?? '',
      },
      body: JSON.stringify(data),
    })
    return await response.json()
  } catch (e) {
    return e
  }
}

export const addRequirement = async (data, lang) => {
  try {
    const source = data?.source
    delete data['source']
    const authToken = data?.authToken
    delete data['authToken']
    const response = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL_V2}requirement-manager/add-requirement`, {
      method: 'POST',
      headers: {
        locale: lang,
        'Content-Type': 'application/json',
        Authorization: authToken ?? window.localStorage.getItem('authToken') ?? '',
        source,
        'x-request-id': uniqueIdGenerator.getId()
      },
      body: JSON.stringify(data),
    })
    return await response.json()
  } catch (e) {
    return e
  }
}

export const kmlData = async (cityId) => {
  //alert(cityId)
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/maps/kml`, {
      method: 'POST',
      headers: {
        //locale: lang,
        'Content-Type': 'application/json',
        //Authorization: window.localStorage.getItem('authToken') ?? '',
      },
      body: JSON.stringify({
        cityId,
        zoneId: null, // having this as dynamic have no effect
        kmlFor: 'district',
        includeProperties: true,
        view: 'MAP_VIEW_MOBILE',
        propertyTypeData: [null],
        type: 'residential', // this would be dynamic
        countryId: '1',
        regionId: null,
      }),
    })
    return await response.json()
  } catch (e) {
    return e
  }
}

export const saveRequirementDraft = async (data, lang, setHasValuesChanged) => {
  try {
    const response = await fetch(`${process.env.NEXT_PUBLIC_GATEWAY_URL}requirement-manager/mm-draft`, {
      method: 'POST',
      headers: {
        locale: lang,
        'Content-Type': 'application/json',
        Authorization: window.localStorage.getItem('authToken'),
        'x-request-id': uniqueIdGenerator.getId()
      },
      body: JSON.stringify(data),
    })
    setHasValuesChanged(true)
    return await response.json()
  } catch (e) {
    return e
  }
}

const setCookieOnLogin = (token, refreshToken, authStoredDate, userID, cookie) => {
  const domain = location.host
  const cookieOptions = {
    path: '/',
    expires: new Date(new Date().setDate(new Date().getDate() + 60)), // 1 moth expiry added to cookie
    secure: true,
  }
  if (domain.split('.').length > 1) {
    const domainParts = domain.split('.')
    domainParts.shift()
    cookieOptions.domain = '.' + domainParts.join('.')
  }
  cookie.set('authToken', token, cookieOptions)
  cookie.set('userId', userID, cookieOptions)
  cookie.set('refrshToken', refreshToken, cookieOptions)
  cookie.set('authStoredDate', authStoredDate, cookieOptions)
  if (domain.match(/[a-z]/i) == null) {
    delete cookieOptions.domain
    cookie.set('authToken', token, cookieOptions)
    cookie.set('userId', userID, cookieOptions)
    cookie.set('refrshToken', refreshToken, cookieOptions)
    cookie.set('authStoredDate', authStoredDate, cookieOptions)
  } else if (domain.split('.').length > 1 && (cookie.get('authToken') == null || cookie.get('authToken') != token)) {
    cookieOptions.domain = '.' + location.host
    cookie.set('authToken', token, cookieOptions)
    cookie.set('userId', userID, cookieOptions)
    cookie.set('refrshToken', refreshToken, cookieOptions)
    cookie.set('authStoredDate', authStoredDate, cookieOptions)
  }
}

export const getRequirementDraft = async (data, lang, cookie) => {
  try {
    const { id, source } = data
    const token = window.localStorage.getItem('authToken') || ''
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_GATEWAY_URL}requirement-manager/mm-draft?draft_id=${id}${
        token ? '' : `&source=${source}`
      }`,
      {
        method: 'GET',
        headers: {
          locale: lang,
          'Content-Type': 'application/json',
        },
      },
    )
    const res = await response.json()
    if (res?.status) {
      if (Object.keys(res?.data?.auto_login)?.length) {
        window.localStorage.setItem('authToken', res?.data?.auto_login?.token)
        window.localStorage.setItem('firstName', res?.data?.auto_login?.fullName)
        window.localStorage.setItem('phoneNumber', res?.data?.auto_login?.phoneNumber)
        window.localStorage.setItem('countryCode', res?.data?.auto_login?.phoneNumberCountryCode)
        window.localStorage.setItem('userId', res?.data?.auto_login?.id)
        window.localStorage.setItem('refreshToken', res?.data?.auto_login?.refreshToken)
        const authStoredDate = dayjs().format()
        window.localStorage.setItem('authStoredDate', authStoredDate)
        setCookieOnLogin(res?.data?.auto_login?.token, res?.data?.auto_login?.refreshToken, authStoredDate, res?.data?.auto_login?.id, cookie)
      }
      return res
    }
  } catch (e) {
    return e
  }
}

export const resolveDeeplinkingUrl = async (verifyUrl, lang, cookie, dispatch, source) => {
  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL_V3}/resolve-deeplinking-url?url=${encodeURIComponent(verifyUrl)}`,
      {
        method: 'GET',
        headers: {
          locale: lang,
          'Content-Type': 'application/json',
          'x-request-id': uniqueIdGenerator.getId()
        },
      },
    )
    const res = await response.json()
    if (res?.data?.parameters?.id) {
      const eventData = {draft_id: res?.data?.parameters?.id, source}
      dispatch({ type: 'DRAFT_ID', payload: res?.data?.parameters?.id })
      pushDataLayerEventHandler(window, lang, 'MM_deeplink_clicked', 'SYR', eventData)
      return getRequirementDraft(res?.data?.parameters, lang, cookie)
    } else if (typeof window != 'undefined') {
      return null
    }
  } catch (e) {
    return e
  }
}
